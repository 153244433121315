<template>
  <div class="modal">
    <sweet-modal ref="modal" @close="onModalClose">
      <slot name="title" slot="title"></slot>

      <slot></slot>

      <button v-if="hasCancelButton" slot="button" class="cancel border border-grey-medium text-grey-dark hover:bg-gray-400 hover:text-white" @click="$refs.modal.close()">Cancel</button>

      <slot name="button" slot="button"></slot>
    </sweet-modal>
  </div>
</template>

<script>
import {SweetModal} from 'sweet-modal-vue'

export default {
  name: 'Modal',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    hasCancelButton: {
      type: Boolean,
      default: true
    }
  },
  components: {
    SweetModal
  },
  watch: {
    isOpen: function(val) {
      val ? this.$refs.modal.open() : this.$refs.modal.close()
    }
  },

  beforeDestroy() {
    document.querySelector('body').style.height = null;
    document.querySelector('body').style.overflow = null;
  },

  methods: {
    onModalClose() {
      this.$emit('close')
    }
  }
};
</script>

<style scoped>
.cancel {

}

>>> .sweet-modal-overlay {
  @apply bg-grey-medium bg-opacity-80;
}

>>> .sweet-modal {
  min-height: 280px;
  @apply rounded-lg p-4 lg:px-8 lg:py-10 flex flex-col;
}

>>> .sweet-title {
  @apply h-auto leading-none max-w-lg whitespace-normal overflow-auto mb-5 mx-auto md:mx-0;
}

>>> .sweet-title .attention {
  background: #FAC7B5;
  color: #E61F21;
  @apply flex-shrink-0 rounded-full w-16 h-16 flex items-center justify-center mb-4 md:mb-0 md:mr-8;
}

>>> .sweet-title,
>>> .sweet-content,
>>> .sweet-buttons{
  @apply border-0 p-0 !important;
}

>>> .sweet-modal .sweet-box-actions {
  @apply top-5 right-5;
}

>>> .sweet-action-close {
  color: #9CA3AF !important;
  @apply w-auto h-auto hover:bg-transparent !important;
}

>>> .sweet-buttons button {
  min-width: 130px;
  @apply text-sm lg:text-medium py-2.5 px-5 rounded-full font-medium;
}

>>> .sweet-title small,
>>> .sweet-content {
  @apply text-grey-dark text-base block;
}

>>> .sweet-buttons {
  @apply flex justify-between lg:justify-end lg:space-x-7 mt-auto;
  @apply static !important;
}

>>> .sweet-buttons .remove {
  background: #E61F21;
  @apply hover:bg-red-dark text-white;
}

</style>
